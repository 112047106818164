import * as RA from "react-admin";
import PercentField from "../../common/PercentField";
import ListingsDataGrid from "../listings/listingsDataGrid";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import CustomPagination from "../../layout/CustomPagination";
import HtmlSanitize from "../../common/HtmlSanitize";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import useStyles from "../../hooks/Styles";
import { SeatingType, CateringType } from "../../../utils/enums";
import TevoMappingsList from "../venues/tevoMappings/list";
import React from "react";

export default (props: RA.ShowProps) => (
  <RA.Show {...props}>
    <PersistedNameContainer {...props}>
      <AssetShow {...props} />
    </PersistedNameContainer>
  </RA.Show>
);

const AssetShow = ({ ...props }) => {
  const styles = useStyles();
  const { venueId } = props.record;

  return (
    <RA.TabbedShowLayout {...props}>
      <RA.Tab label="Properties">
        {venueId && (
          <HtmlSanitize>
            <Button
              variant="contained"
              color="primary"
              className={styles.marginBottom}
              component={Link}
              to={{
                pathname: `/venues/${venueId}/1`,
              }}
            >
              See venue mappings
            </Button>
          </HtmlSanitize>
        )}
        <RA.BooleanField source="ignore" label="ignored" />
        <RA.TextField source="name" />
        <RA.TextField source="venueSection" />
        <RA.TextField source="label" />
        <RA.DateField
          source="createdAt"
          locales="en-us"
          options={{ timeZone: "America/Los_Angeles" }}
        />
        <RA.NumberField source="maxSeatsAvailable" />
        <RA.NumberField source="maxParking" />
        <RA.SelectField source="seatingType" choices={SeatingType} />
        <RA.SelectField source="cateringType" choices={CateringType} />
        <PercentField source="margin" />
        <PercentField source="confirmMargin" />
        <RA.NumberField source="xPoint" />
        <RA.NumberField source="yPoint" />
        <RA.TextField source="notes" />
        <RA.TextField source="internalNotes" />
        <RA.TextField source="virtualMapSection" />
        <RA.BooleanField source="privateRestroom" />
        <RA.BooleanField source="wifi" />
        <RA.BooleanField source="vipClubAccess" />
        <RA.BooleanField source="tv" />
        <RA.BooleanField source="avHookup" />
        <RA.BooleanField source="couches" />
        <RA.BooleanField source="cocktailTable" />
        <RA.BooleanField source="refrigerator" />
      </RA.Tab>
      <RA.Tab label="Relationships">
        <RA.ReferenceField label="Venue" source="venueId" reference="venues">
          <RA.ChipField source="name" />
        </RA.ReferenceField>
        <RA.ReferenceField
          label="Listing Partner"
          source="listingPartnerId"
          reference="listingPartners"
        >
          <RA.ChipField source="email" />
        </RA.ReferenceField>
        <RA.ReferenceManyField
          label="Tevo Mappings"
          reference="ticketEvoMappings"
          target="assetId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <TevoMappingsList />
        </RA.ReferenceManyField>
        <RA.ReferenceManyField
          label="Listings"
          reference="listings"
          target="assetId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <ListingsDataGrid showEventsData={true} showStatusColumn={true} />
        </RA.ReferenceManyField>
      </RA.Tab>
    </RA.TabbedShowLayout>
  );
};
