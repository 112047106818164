// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-final-form";
import * as RA from "react-admin";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import {
  getDataFromCustomRoute,
  postDataToCustomRoute,
} from "../../providers/DataProvider";
import useAuthentication from "../../hooks/useAuthentication";
import { CateringType, SeatingType } from "../../../utils/enums";
import useStyles from "../../hooks/Styles";
import HtmlSanitize from "../../common/HtmlSanitize";
import MapSection from "../../common/MapSection";
import ThreeDView from "../../common/3DViewer";
import NumberInput from "../../common/NumberInput";
import ListingsDataGrid from "../listings/listingsDataGrid";
import CustomPagination from "../../layout/CustomPagination";
import CustomToolBar from "../../layout/CustomToolBar";
import TevoMappingsList from "../venues/tevoMappings/list";
import { Divider } from "@material-ui/core";
import AssetPhotosList from "../assetPhotos/list";
import AddNewSubRelationshipButton from "./components/AddNewSubResourceButton";
import {
  fetchAllMappedAssetsByVenueApiUrl,
  fetchAssetTemplatesByVenueApiUrl,
  postAssetAttachApiUrl,
} from "../../../utils/urls";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();
  const {
    id,
    venueId,
    xPoint,
    yPoint,
    virtualMapSection,
    name,
    locationHidden,
  } = props.record;
  const [venueMaps, setVenueMaps] = useState<null | Record<any, any>[]>(null);
  const [venueVirtualMaps, setVenueVirtualMaps] = useState<
    null | Record<any, any>[]
  >(null);
  const venue = RA.useGetOne("venues", venueId).data as RA.Record;
  const tevoMappingsCall = RA.useGetManyReference(
    "ticketEvoMappings",
    "assetId",
    props.record.id,
    { page: 1, perPage: 50 },
    { field: "id", order: "DESC" },
    {},
    "assets"
  );
  const isUnmappedAsset =
    tevoMappingsCall.loaded && tevoMappingsCall.ids.length === 0;

  // Set venue maps
  useEffect(() => {
    if (venue?.maps && venue?.maps.length) {
      setVenueMaps(venue.maps);
    }

    if (venue?.virtualMaps && venue?.virtualMaps.length) {
      setVenueVirtualMaps(venue.virtualMaps);
    }
  }, [venue]);

  // @ts-ignore
  if (view === "edit" && !venue) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        {venueId && (
          <HtmlSanitize>
            <Button
              variant="contained"
              color="primary"
              className={styles.marginBottom}
              component={Link}
              to={{
                pathname: `/venues/${venueId}/1`,
              }}
            >
              See venue mappings
            </Button>
          </HtmlSanitize>
        )}
        {view === "edit" && isUnmappedAsset && (
          <UnmappedAssetFields name={name} venueId={venueId} id={id} />
        )}
        <AssetTemplateSelector venueId={venueId} />
        <RA.BooleanInput
          source="ignore"
          label="Ignore this asset"
          initialValue={view === "create" ? false : props.record.ignore}
        />
        <RA.TextInput source="name" validate={RA.required()} />
        <RA.SelectInput
          source="venueSection"
          choices={venue?.sectionNames
            .map((obj: { field: string }) => obj.field)
            .map((choice: string) => ({
              id: choice,
              name: choice,
            }))}
        />
        {/*<RA.SelectInput*/}
        <RA.TextInput source="label" />
        <NumberInput source="maxSeatsAvailable" validate={RA.required()} />
        <NumberInput source="maxParking" />
        <RA.SelectInput
          source="seatingType"
          choices={SeatingType}
          validate={RA.required()}
        />
        <RA.SelectInput
          source="cateringType"
          choices={CateringType}
          validate={RA.required()}
        />
        <NumberInput source="margin" />
        <NumberInput source="confirmMargin" />
        <RA.TextInput source="notes" multiline minRows={8} />
        <RA.TextInput source="internalNotes" multiline minRows={8} />
        <RA.BooleanInput
          source="privateRestroom"
          initialValue={
            view === "create" ? false : props.record.privateRestroom
          }
        />
        <RA.BooleanInput
          source="wifi"
          initialValue={view === "create" ? true : props.record.wifi}
        />

        <RA.BooleanInput
          source="vipClubAccess"
          initialValue={view === "create" ? false : props.record.vipClubAccess}
        />
        <RA.BooleanInput
          source="tv"
          initialValue={view === "create" ? false : props.record.tv}
        />
        <RA.BooleanInput
          source="avHookup"
          initialValue={view === "create" ? false : props.record.avHookup}
        />
        <RA.BooleanInput
          source="couches"
          initialValue={view === "create" ? false : props.record.couches}
        />
        <RA.BooleanInput
          source="cocktailTable"
          initialValue={view === "create" ? false : props.record.cocktailTable}
        />
        <RA.BooleanInput
          source="refrigerator"
          initialValue={view === "create" ? false : props.record.refrigerator}
        />
      </RA.FormTab>
      <RA.FormTab label="Maps">
        {venueMaps && (
          <MapSection
            locationHidden={locationHidden}
            xPoint={xPoint}
            yPoint={yPoint}
            styles={styles}
            maps={venueMaps}
            activeTabIndex={1}
          />
        )}
        {venueVirtualMaps && (
          <ThreeDView
            maps={venueVirtualMaps}
            virtualMapSection={virtualMapSection}
          />
        )}
      </RA.FormTab>
      <RA.FormTab label="Photos">
        <RA.ReferenceManyField
          label="Verified Photos"
          reference="assetPhotos"
          target="assetId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetPhotosList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          assetId={props.record.id}
          path="assetPhotos"
          resourceName="photo"
        />
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        {view === "edit" && (
          <HtmlSanitize>
            <Alert className={styles.alert} severity="info">
              Note: Asset listings can not be created in this view. You must
              first create the listing and then attach it to the asset from the
              listing create view.
            </Alert>
          </HtmlSanitize>
        )}
        <RA.ReferenceInput
          label="Venue"
          source="venueId"
          reference="venues"
          validate={RA.required()}
        >
          <RA.AutocompleteInput source="name" optionText="name" />
        </RA.ReferenceInput>
        <RA.ReferenceInput
          label="Listing Partner"
          source="listingPartnerId"
          reference="listingPartners"
        >
          <RA.AutocompleteInput source="email" optionText="email" />
        </RA.ReferenceInput>
        {view === "edit" && (
          <RA.ReferenceManyField
            label="Tevo Mappings"
            reference="ticketEvoMappings"
            target="assetId"
            pagination={<CustomPagination />}
            perPage={50}
          >
            <TevoMappingsList />
          </RA.ReferenceManyField>
        )}
        {view === "edit" && (
          <RA.ReferenceManyField
            label="Listings"
            reference="listings"
            target="assetId"
            pagination={<CustomPagination />}
            perPage={50}
          >
            <ListingsDataGrid showStatusColumn={true} />
          </RA.ReferenceManyField>
        )}
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

const AssetTemplateSelector = ({
  venueId,
}: {
  venueId: number | undefined;
}) => {
  if (!venueId) {
    return null;
  }

  const styles = useStyles();
  const form = useForm();
  const { user, authToken } = useAuthentication();

  const [assetTemplates, setAssetTemplates] = useState<
    null | Record<any, any>[]
  >(null);
  const [selectedAssetTemplate, setSelectedAssetTemplate] = useState<
    null | string
  >(null);

  // Set asset blueprints
  useEffect(() => {
    if (!authToken) {
      return null;
    }

    (async () => {
      const url = fetchAssetTemplatesByVenueApiUrl(venueId);
      const templates = await getDataFromCustomRoute(url, authToken, user);
      setAssetTemplates(templates);
    })();
  }, [user, authToken]);

  useEffect(() => {
    if (selectedAssetTemplate && assetTemplates?.length) {
      // @ts-ignore
      const templateRecord = assetTemplates.find(
        (bp) => bp.displayName === selectedAssetTemplate
      );
      delete templateRecord?.id;
      delete templateRecord?.displayName;

      for (const key in templateRecord) {
        if (templateRecord[key]) {
          form.change(key, templateRecord[key]);
        }
      }
    }
  }, [selectedAssetTemplate]);

  if (!assetTemplates || assetTemplates.length < 1) {
    return null;
  }

  return (
    <HtmlSanitize>
      <select
        name="assetTemplates"
        className={styles.marginBottom}
        onChange={(e) => setSelectedAssetTemplate(e.target.value)}
      >
        <option value="" disabled selected>
          -- Autofill using an asset template --
        </option>
        {assetTemplates.map((template, i) => (
          <option value={template.displayName} key={i}>
            {template.displayName}
          </option>
        ))}
      </select>
    </HtmlSanitize>
  );
};

const UnmappedAssetFields = ({ name, venueId, id }) => {
  const styles = useStyles();
  const form = useForm();
  const { user, authToken } = useAuthentication();
  const [unmappedAction, setUnmappedAction] = useState("");
  const [availableAssetsToAttach, setAvailableAssetsToAttach] = useState([]);
  const [attachedAsset, setAttachedAsset] = useState(null);

  let section = "";
  let row = "";
  try {
    if (name) {
      section = name.split(",")[0];
      row = name.split(",")[1].replace("Row", "").trim();
    }
  } catch (e) {}

  // Set available assets to attach
  useEffect(() => {
    if (!authToken) {
      return null;
    }
    (async () => {
      const url = fetchAllMappedAssetsByVenueApiUrl(venueId);
      const assets = await getDataFromCustomRoute(url, authToken, user);
      setAvailableAssetsToAttach(assets);
    })();
  }, [authToken, user]);

  // Clear out existing name field when creating a new mapping
  useEffect(() => {
    if (unmappedAction === "newMapping") {
      form.change("name", "");
    }
  }, [unmappedAction]);

  return (
    <HtmlSanitize>
      <select
        name="assetTemplates"
        className={styles.marginBottom}
        onChange={(e) => {
          setUnmappedAction(e.target.value);
        }}
      >
        <option value="" disabled selected>
          -- This is an Unmapped Asset: --
        </option>
        <option value="newMapping">Create a new mapping</option>
        <option value="existing">Attach to an existing asset</option>
      </select>
      {unmappedAction === "newMapping" && (
        <div>
          <div>
            <RA.TextInput
              source="mappingSection"
              label="Section"
              className={styles.input}
              defaultValue={section}
            />
          </div>
          <div>
            <RA.TextInput
              source="mappingRow"
              label="Row"
              className={styles.input}
              defaultValue={row}
            />
          </div>
          <Divider className={styles.marginBottom} />
        </div>
      )}
      {unmappedAction === "existing" && (
        <div>
          <Autocomplete
            options={availableAssetsToAttach}
            getOptionLabel={(asset) =>
              `${asset.name}${asset.label ? ` - ${asset.label}` : ``}`
            }
            style={{ width: 300, float: "left" }}
            onChange={(e, val) => setAttachedAsset(val)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Existing Asset"
                variant="outlined"
              />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            className={styles.assetAttachButton}
            disabled={!attachedAsset}
            onClick={() => {
              if (attachedAsset) {
                const url = postAssetAttachApiUrl();
                const data = {
                  assetId: id,
                  attachId: attachedAsset.id,
                  section,
                  row,
                };
                postDataToCustomRoute(url, data, authToken, user).then(
                  (data) => {
                    window.location.replace(
                      window.location.href.replace(id, data.id)
                    );
                  }
                );
              }
            }}
          >
            Attach
          </Button>
          <Divider className={styles.marginBottom} />
        </div>
      )}
    </HtmlSanitize>
  );
};

export default Form;
