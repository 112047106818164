import * as RA from "react-admin";
import CustomToolBar from "../../../layout/CustomToolBar";
import NumberInput from "../../../common/NumberInput";
import { CateringType, SeatingType } from "../../../../utils/enums";
import useStyles from "../../../hooks/Styles";
import { parse } from "query-string";
import Typography from "@material-ui/core/Typography";
import HtmlSanitize from "../../../common/HtmlSanitize";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();
  const { record } = props;
  const params = parse(`?${location.hash.split("?")[1]}`);
  const venueId = record.venueId
    ? record.venueId
    : parseInt(params.venueId as string);
  const venue = RA.useGetOne("venues", venueId).data as RA.Record;

  if (!venue) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <RA.SimpleForm
      {...props}
      initialValues={{ venueId }}
      toolbar={
        <CustomToolBar
          view={view}
          deleteRedirectPath={`/venues/${venueId}/4`}
        />
      }
      redirect={`/venues/${venueId}/4`}
    >
      {view === "create" && (
        <HtmlSanitize>
          <Typography variant="h5" className={styles.header}>
            New Asset Template: {venue.name}
          </Typography>
        </HtmlSanitize>
      )}
      {view === "edit" && (
        <RA.ReferenceField label="Venue" source="venueId" reference="venues">
          <RA.TextField source="name" />
        </RA.ReferenceField>
      )}
      <RA.TextInput
        source="displayName"
        helperText="This is the name of the asset template"
        validate={RA.required()}
      />
      <RA.TextInput
        source="name"
        helperText="This is the name of the asset itself, not required"
      />
      <NumberInput source="maxSeatsAvailable" />
      <RA.SelectInput
        source="venueSection"
        choices={venue?.sectionNames
          .map((obj: { field: string }) => obj.field)
          .map((choice: string) => ({
            id: choice,
            name: choice,
          }))}
      />
      <RA.SelectInput
        source="seatingType"
        choices={SeatingType}
        validate={RA.required()}
      />
      <RA.SelectInput
        source="cateringType"
        choices={CateringType}
        validate={RA.required()}
      />
      <RA.TextInput source="notes" multiline={true} minRows={8} />
    </RA.SimpleForm>
  );
};

export default Form;
