import * as RA from "react-admin";
import { Create, Event } from "@material-ui/icons";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import useAuthentication from "../../hooks/useAuthentication";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import { CateringType, ListingSource } from "../../../utils/enums";
import React from "react";
import { useRefresh } from "react-admin";
import {
  boxOfficeCheckoutUrl,
  boxOfficeListingUrl,
  triggerTevoListingSyncUrl,
} from "../../../utils/urls";

const Container = (props: any) => {
  const event = RA.useGetOne("events", props.record.eventId).data as RA.Record;

  if (!event) {
    return null;
  }

  const listingLink = props.record
    ? boxOfficeListingUrl(event.slug, props.record.id)
    : null;
  const checkoutlink = props.record
    ? boxOfficeCheckoutUrl(props.record.id)
    : null;

  return (
    <PersistedNameContainer
      name={`Listing event: ${event.name}`}
      link={listingLink}
      checkoutlink={checkoutlink}
    >
      <ListingsShow {...props} />
    </PersistedNameContainer>
  );
};

const ShowActions = ({ listingId }: { listingId: string | undefined }) => {
  const { user, authToken } = useAuthentication();
  const refresh = useRefresh();

  if (!listingId || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/listings/${listingId}`
          )
        }
        label={"Edit"}
      >
        <Create />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url = triggerTevoListingSyncUrl(listingId);
          await getDataFromCustomRoute(url, authToken, user);
          alert("Sync with tevo scheduled");
          refresh();
        }}
        label="Schedule Sync with Tevo"
      >
        <Event />
      </RA.Button>
    </RA.TopToolbar>
  );
};

const ListingsShow = ({ ...props }) => (
  <RA.TabbedShowLayout {...props}>
    <RA.Tab label="Properties">
      <RA.TextField source="bookingMethod" />
      <RA.TextField source="accessType" />
      <RA.SelectField source="listingSource" choices={ListingSource} />
      <RA.TextField source="status" />
      <RA.NumberField
        source="wholesalePrice"
        options={{ style: "currency", currency: "USD" }}
      />
      <RA.NumberField
        source="retailPrice"
        options={{ style: "currency", currency: "USD" }}
      />
      <RA.NumberField source="margin" />
      <RA.BooleanField source="marginOverride" />
      <RA.DateField
        source="createdAt"
        locales="en-us"
        options={{ timeZone: "America/Los_Angeles" }}
      />
      <RA.SelectField source="cateringType" choices={CateringType} />
      <RA.TextField source="tevoId" label="Ticket Evolution ID" />
      <RA.ArrayField source="ticketGroupSizes">
        <RA.SingleFieldList linkType={false}>
          <RA.ChipField source="field" />
        </RA.SingleFieldList>
      </RA.ArrayField>
      <RA.TextField source="notes" />
      <RA.TextField source="internalNotes" />
      <RA.BooleanField source="instantDelivery" />
      <RA.BooleanField source="offlineOnly" label="Offline listing" />
      <RA.BooleanField
        source="convertedSharedToPrivate"
        label="Converted from shared to private"
      />
    </RA.Tab>
    <RA.Tab label="Relationships">
      <RA.ReferenceField label="Asset" source="assetId" reference="assets">
        <RA.ChipField source="name" />
      </RA.ReferenceField>
      <RA.ReferenceField label="Event" source="eventId" reference="events">
        <RA.ChipField source="name" />
      </RA.ReferenceField>
    </RA.Tab>
  </RA.TabbedShowLayout>
);

export default (props: RA.ShowProps) => {
  return (
    <RA.Show {...props} actions={<ShowActions listingId={props.id} />}>
      <Container {...props} />
    </RA.Show>
  );
};
