import * as RA from "react-admin";
import { Create, Event } from "@material-ui/icons";
import TevoMappingsList from "./tevoMappings/list";
import VenueMapsList from "./maps/list";
import VenuePhotosList from "./photos/list";
import AssetPhotosList from "../assetPhotos/list";
import EventsDataGrid from "../events/eventsDataGrid";
import AssetsDataGrid from "../assets/assetsDataGrid";
import { TimeZone, VenueType, Country } from "../../../utils/enums";
import useStyles from "../../hooks/Styles";
import PersistedNameContainer from "../../common/PersistedNameContainer";
import AddNewSubRelationshipButton from "./components/AddNewSubResourceButton";
import CustomPagination from "../../layout/CustomPagination";
import VenueVirtualMapsList from "./virtualMaps/list";
import React from "react";
import AssetTemplatesList from "./assetTemplates/list";
import useAuthentication from "../../hooks/useAuthentication";
import { getDataFromCustomRoute } from "../../providers/DataProvider";
import {
  boxOfficeVenueUrl,
  triggerTevoVenueSyncUrl,
} from "../../../utils/urls";

const Container = (props: any) => {
  const venueLink = props.record ? boxOfficeVenueUrl(props.record.slug) : null;

  return (
    <PersistedNameContainer name={props.record.name} link={venueLink}>
      <VenueShow {...props} />
    </PersistedNameContainer>
  );
};

const ShowActions = ({ venueId }: { venueId: string | undefined }) => {
  const { user, authToken } = useAuthentication();

  if (!venueId || !authToken) {
    return null;
  }

  return (
    <RA.TopToolbar>
      <RA.Button
        onClick={() =>
          window.location.assign(
            `${process.env.REACT_APP_APPLICATION_URL}/#/venues/${venueId}`
          )
        }
        label={"Edit"}
      >
        <Create />
      </RA.Button>
      <RA.Button
        onClick={async () => {
          const url = triggerTevoVenueSyncUrl(venueId);
          await getDataFromCustomRoute(url, authToken, user);
          alert("Sync with tevo scheduled");
        }}
        label="Schedule Sync with Tevo"
      >
        <Event />
      </RA.Button>
    </RA.TopToolbar>
  );
};

const VenueShow = ({ ...props }) => {
  const styles = useStyles();
  const { record } = props;

  return (
    <RA.TabbedShowLayout {...props}>
      <RA.Tab label="Properties">
        <RA.TextField source="name" />
        <RA.SelectField source="venueType" choices={VenueType} />
        <RA.TextField source="ticketEvoId" label="Ticket Evo Id" />
        <RA.NumberField source="margin" />
        <RA.TextField source="slug" />
        <RA.TextField source="street" label="address" />
        <RA.TextField source="city" />
        <RA.TextField source="state" />
        <RA.TextField source="zip" />
        <RA.SelectField source="country" choices={Country} />
        <RA.TextField source="lat" />
        <RA.TextField source="long" />
        <RA.SelectField source="timeZone" choices={TimeZone} />
        <RA.NumberField source="privateSuiteThreshold" />
        <RA.TextField source="ticketPortalUrl" />
        <RA.BooleanField
          source="syncExistingListings"
          label="Include in existing listings sync"
        />
      </RA.Tab>
      <RA.Tab label="Tevo Mappings">
        <RA.ArrayField source="listingTerms">
          <RA.SingleFieldList linkType={false}>
            <RA.ChipField source="field" />
          </RA.SingleFieldList>
        </RA.ArrayField>
        <RA.ArrayField source="listingExclusions">
          <RA.SingleFieldList linkType={false}>
            <RA.ChipField source="field" />
          </RA.SingleFieldList>
        </RA.ArrayField>
        <RA.ReferenceManyField
          label="Mappings"
          reference="ticketEvoMappings"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <TevoMappingsList />
        </RA.ReferenceManyField>
      </RA.Tab>
      <RA.Tab label="Maps">
        {/*2D Maps*/}
        <RA.ReferenceManyField
          label="2D Maps"
          reference="venueMaps"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <VenueMapsList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="venueMaps"
          resourceName="venue map"
        />
        {/*3D Maps*/}
        <RA.ReferenceManyField
          label="3D Venue Maps"
          reference="venueVirtualMaps"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <VenueVirtualMapsList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="venueVirtualMaps"
          resourceName="venue 3D map"
        />
      </RA.Tab>
      <RA.Tab label="Photos">
        <RA.ImageField source="featuredImage" />
        <RA.ReferenceManyField
          label=""
          reference="venuePhotos"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <VenuePhotosList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="venuePhotos"
          resourceName="venue ohoto"
        />

        <RA.ReferenceManyField
          label=""
          reference="assetPhotos"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetPhotosList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="assetPhotos"
          resourceName="asset photo"
        />
      </RA.Tab>
      <RA.Tab label="Asset Management">
        <RA.ReferenceManyField
          label=""
          reference="assetTemplates"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetTemplatesList />
        </RA.ReferenceManyField>
        <AddNewSubRelationshipButton
          venueId={record.id}
          path="assetTemplates"
          resourceName="asset template"
        />
      </RA.Tab>
      <RA.Tab label="Relationships">
        <RA.ReferenceManyField
          label="Home Teams"
          reference="performers"
          target="homeVenueId"
        >
          <RA.SingleFieldList>
            <RA.ChipField source="name" className={styles.marginBottom} />
          </RA.SingleFieldList>
        </RA.ReferenceManyField>
        <RA.ReferenceManyField
          label="Assets"
          reference="assets"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <AssetsDataGrid showVenueData={false} />
        </RA.ReferenceManyField>
        <RA.ReferenceManyField
          label="Events"
          reference="events"
          target="venueId"
          pagination={<CustomPagination />}
          perPage={50}
        >
          <EventsDataGrid showVenueData={false} showExpiredColumn={true} />
        </RA.ReferenceManyField>
      </RA.Tab>
    </RA.TabbedShowLayout>
  );
};

export default (props: RA.ShowProps) => {
  return (
    <RA.Show {...props} actions={<ShowActions venueId={props.id} />}>
      <Container {...props} />
    </RA.Show>
  );
};
