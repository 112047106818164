export const boxOfficeDeploymentsUrl = () =>
  `${process.env.REACT_APP_BOX_OFFICE_DEPLOYMENTS_URL}`;

export const boxOfficeVenueUrl = (slug: string) =>
  `${process.env.REACT_APP_BOX_OFFICE_URI}/venues/${slug}-suites`;

export const boxOfficePvpUrl = (categorySlug: string, pvpSlug: string) =>
  `${process.env.REACT_APP_BOX_OFFICE_URI}/${categorySlug}/${pvpSlug}-suites`;

export const boxOfficeEventUrl = (venueSlug: string, eventSlug: string) =>
  `${process.env.REACT_APP_BOX_OFFICE_URI}/venues/${venueSlug}-suites/${eventSlug}`;

export const boxOfficeListingUrl = (eventSlug: string, listingId: number) =>
  `${process.env.REACT_APP_BOX_OFFICE_URI}/event/${eventSlug}?listing=${listingId}`;

export const boxOfficeCheckoutUrl = (listingId: number) =>
  `${process.env.REACT_APP_BOX_OFFICE_URI}/checkout?listingId=${listingId}`;

export const boxOfficeOrderReceiptPageUrl = (orderNumber: number) =>
  `${process.env.REACT_APP_BOX_OFFICE_URI}/order/receipt/${orderNumber}`;

export const fetchAssetApiUrl = (assetId: number) =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/assets/${assetId}`;

export const fetchAssetTemplatesByVenueApiUrl = (venueId: number) =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/assetTemplates?filter=%7B%22venueId%22%3A${venueId}%7D`;

export const fetchAllMappedAssetsByVenueApiUrl = (venueId: number) =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/assets/allMapped?filter=%7B%22venueId%22%3A${venueId}%7D`;

export const fetchMarginApiUrl = (
  assetId: number,
  venueId: number,
  eventId: number
) =>
  `${
    process.env.REACT_APP_DATA_PROVIDER_URI
  }/margin/findFromData?${new URLSearchParams({
    assetId: assetId.toString(),
    venueId: venueId.toString(),
    eventId: eventId.toString(),
  })}`;

export const fetchTotalListingsValueUrl = () =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/ra/all-listings-active-value`;

export const fetchTotalInstantBookListingsValueUrl = () =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/ra/all-listings-active-instant-book-value`;

export const fetchQueueStatsUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/queue-stats`;

export const postAssetAttachApiUrl = () =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/assets/attachExistingAsset`;

export const triggerBoxOfficeDeployUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/box-office-deploy`;

export const triggerEmptyQueueUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/empty-queue`;

export const triggerPauseQueueUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/pause-queue`;

export const triggerResumeQueueUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/resume-queue`;

export const triggerTevoFeedSyncUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/schedule-tevo-feed-sync`;

export const triggerTevoVenueSyncUrl = (venueId: string) =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/schedule-venue-sync/${venueId}`;

export const triggerTevoEventSyncUrl = (eventId: string) =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/schedule-event-sync/${eventId}`;

export const triggerTevoListingSyncUrl = (listingId: string) =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/schedule-listing-sync/${listingId}`;

export const triggerExistingListingsSyncUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/schedule-existing-listings-sync`;

export const triggerSearchIndexRebuildUrl = () =>
  `${process.env.REACT_APP_FEED_CONSUMER_URL}/rebuild-search-index`;

export const triggerMarginSyncUrl = () =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/ra/schedule-listings-margin-sync`;

export const triggerEventTevoListingsPurgeUrl = () =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/ra/delete-event-tevo-listings`;

export const triggerMailerCustomerEmailUrl = (orderId: string) =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/ra/mailer/resendSaleSuccessEmail/${orderId}`;

export const triggerMailerLpEmailUrl = (orderId: string) =>
  `${process.env.REACT_APP_DATA_PROVIDER_URI}/ra/mailer/resendListingPartnerSuccessEmail/${orderId}`;
