import * as RA from "react-admin";
import { Category, PerformerType } from "../../../utils/enums";
import EventsDataGrid from "../events/eventsDataGrid";
import Alert from "@material-ui/lab/Alert";
import HtmlSanitize from "../../common/HtmlSanitize";
import useStyles from "../../hooks/Styles";
import CustomPagination from "../../layout/CustomPagination";
import CustomToolBar from "../../layout/CustomToolBar";
import ImageUploader from "../../common/ImageUploader";

const Form = ({ view = "create", ...props }) => {
  const styles = useStyles();

  return (
    <RA.TabbedForm
      {...props}
      toolbar={<CustomToolBar view={view} />}
      redirect="list"
    >
      <RA.FormTab label="Properties">
        <ImageUploader
          source="featuredImage"
          label="Featured Image"
          helperText="Landscape image. Min 1000px wide."
        />
        <RA.TextInput source="name" validate={RA.required()} />
        <RA.TextInput source="slug" validate={RA.required()} />
        <RA.SelectInput
          source="type"
          choices={PerformerType}
          validate={RA.required()}
        />
        <RA.BooleanInput source="ignore" />
        <RA.BooleanInput source="featured" />
        <RA.SelectInput source="primaryCategory" choices={Category} />
        <RA.ArrayInput source="categories">
          <RA.SimpleFormIterator>
            <RA.SelectInput
              source="field"
              label="category"
              choices={Category}
            />
          </RA.SimpleFormIterator>
        </RA.ArrayInput>
      </RA.FormTab>
      <RA.FormTab label="Relationships">
        <HtmlSanitize>
          <Alert className={styles.alert} severity="info">
            Note: If you want to associate an event with a performer, you must
            do it from the event edit view.
          </Alert>
        </HtmlSanitize>
        <RA.ReferenceInput
          label="Home Venue"
          source="homeVenueId"
          reference="venues"
        >
          <RA.AutocompleteInput source="name" />
        </RA.ReferenceInput>
        <RA.ReferenceArrayInput
          source="performerIds"
          reference="performers"
          label="Related Performers"
          className={styles.input}
        >
          <RA.AutocompleteArrayInput source="name" className={styles.input} />
        </RA.ReferenceArrayInput>
        {view === "edit" && (
          <RA.ReferenceManyField
            label="events"
            reference="events"
            target="eventsByPerformerId"
            pagination={<CustomPagination />}
            perPage={50}
          >
            <EventsDataGrid showVenueData={true} showExpiredColumn={true} />
          </RA.ReferenceManyField>
        )}
      </RA.FormTab>
    </RA.TabbedForm>
  );
};

export default Form;
